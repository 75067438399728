import React, { useMemo } from 'react'
import { css } from '@emotion/core'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'
import arrowSVG from '../icons/arrow.svg'

const rowCss = css`
  text-transform: uppercase;
  font-size: var(--font-size-smaller);
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-darker);
  > td {
    box-sizing: content-box;
    :not(:last-child) {
      padding-right: 2em;
    }
  }
  time {
    color: var(--color-darkest);
  }
  a[target='_blank'] {
    color: var(--color-darkest);
  }
`

const linkCss = css`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  > img {
    margin-left: 0.5rem;
    min-width: 1em;
    width: 1em;
  }
`

const ConcertLineDesktop = ({
  date,
  city,
  place,
  googleMapsUrl,
  bookingUrl,
  hideBooking,
  band,
  bandAlt
}) => {
  const { formatMessage: t, formatDate, formatTime } = useIntl()
  const formattedDate = useMemo(
    () => formatDate(date, { day: 'numeric', month: 'short', year: 'numeric' }),
    [date, formatDate]
  )

  const formattedTime = useMemo(
    () => formatTime(date, { hour: '2-digit', minute: '2-digit' }),
    [date, formatTime]
  )

  const getLocationLink = (location) =>
    googleMapsUrl ? (
      <a
        href={googleMapsUrl}
        css={linkCss}
        target="_blank"
        rel="noopener noreferrer"
      >
        {location}
        <img src={arrowSVG} alt={location} />
      </a>
    ) : (
      location
    )

  const bookingLink =
    !hideBooking && bookingUrl ? (
      <a
        css={linkCss}
        href={bookingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t({ id: 'book' })}
        <img src={arrowSVG} alt={t({ id: 'book' })} />
      </a>
    ) : null

  return (
    <tr css={rowCss}>
      <td style={{ width: '11ch', paddingRight: '1em' }}>
        <time dateTime={date.toISOString()}>{formattedDate}</time>
      </td>
      <td style={{ width: '7ch', paddingRight: '1em' }}>
        <time dateTime={date.toISOString()}>{formattedTime}</time>
      </td>
      <td style={{ width: '20%' }}>
        {band ? (
          <Link to={band.slug}>{band.shortName || band.name}</Link>
        ) : (
          bandAlt
        )}
      </td>
      <td className="l-only" style={{ width: '15%' }} title={place}>
        {getLocationLink(city)}
      </td>
      <td className="l-hide" style={{ width: '15%' }}>
        {city}
      </td>
      <td className="l-hide" style={{ width: '30%' }}>
        {getLocationLink(place)}
      </td>
      <td style={{ width: '10ch', textAlign: 'right' }}>{bookingLink}</td>
    </tr>
  )
}

export default ConcertLineDesktop
