import React, { useMemo } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'
import arrowSVG from '../icons/arrow.svg'

const Flex = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    padding-bottom: var(--space-small);
    margin-bottom: var(--space-small);
    border-bottom: 1px solid var(--color-grey);
  }
`

const Content = styled.div`
  font-size: var(--font-size-small);
  font-weight: 500;
  line-height: 1.3em;
  h5 {
    font-size: 1rem;
    margin: 0 0 var(--space-smaller);
    font-weight: 500;
  }
`

const Place = styled.div`
  color: var(--color-dark);
`

const City = styled.div`
  color: var(--color-dark);
  opacity: 0.9;
  text-transform: uppercase;
  font-size: var(--font-size-smaller);
  margin-top: 0.3rem;
  margin-bottom: var(--space-smaller);
`

const Date = styled.div`
  margin-right: var(--space-medium);
  text-align: center;
  h5 {
    font-size: var(--font-size-medium);
    margin-top: calc(var(--font-size-medium) * -0.3);
    margin-bottom: 0;
    font-weight: 500;
  }
  h6 {
    font-size: var(--font-size-smallest);
    margin: 0;
    font-weight: 500;
  }
`

const linkCss = css`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  font-size: var(--font-size-smaller);
  > img {
    margin-left: 0.5rem;
    min-width: 1em;
    width: 1em;
  }
`

const ConcertLineDesktop = ({
  date,
  city,
  place,
  googleMapsUrl,
  bookingUrl,
  hideBooking,
  band,
  bandAlt
}) => {
  const { formatMessage: t, formatDate, formatTime } = useIntl()
  const formattedDate = useMemo(
    () => formatDate(date, { day: 'numeric', month: 'short' }),
    [date, formatDate]
  )

  const formattedTime = useMemo(
    () => formatTime(date, { hour: '2-digit', minute: '2-digit' }),
    [date, formatTime]
  )

  const getLocationLink = (location) =>
    googleMapsUrl ? (
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        {location}
      </a>
    ) : (
      location
    )

  const bookingLink =
    !hideBooking && bookingUrl ? (
      <a
        css={linkCss}
        href={bookingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t({ id: 'book' })}
        <img src={arrowSVG} alt={t({ id: 'book' })} />
      </a>
    ) : null

  return (
    <Flex>
      <Date>
        <h6>{formattedDate}</h6>
        <h6 style={{ opacity: 0.3 }}>{date.getFullYear()}</h6>
        <h5>{formattedTime}</h5>
      </Date>
      <Content>
        {band ? (
          <Link to={band.slug}>
            <h5>{band.shortName || band.name}</h5>
          </Link>
        ) : (
          <h5>{bandAlt}</h5>
        )}
        <Place>{getLocationLink(place)}</Place>
        <City>{city}</City>
        {bookingLink}
      </Content>
    </Flex>
  )
}

export default ConcertLineDesktop
