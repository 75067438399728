import React from 'react'
import { css } from '@emotion/core'
import { useIntl } from 'react-intl'

import ConcertLineDesktop from './ConcertLineDesktop'
import ConcertLineMobile from './ConcertLineMobile'

const titleCss = css`
  margin-top: 0;
`

const ConcertListMobile = ({ concerts, className, ...props }) => {
  return (
    <div className={`m-only ${className}`}>
      {concerts.map(({ id, ...rest }) => (
        <ConcertLineMobile key={id} {...rest} {...props} />
      ))}
    </div>
  )
}

const ConcertListDesktop = ({ concerts, className, ...props }) => {
  return (
    <table className={`m-hide ${className}`}>
      <tbody>
        {concerts.map(({ id, ...rest }) => (
          <ConcertLineDesktop key={id} {...rest} {...props} />
        ))}
      </tbody>
    </table>
  )
}

export const NextConcertList = ({ hideTitle, emptyTitle, ...props }) => {
  const { formatMessage: t } = useIntl()
  if (props.concerts.length === 0)
    return (
      <h4 css={titleCss}>{emptyTitle || t({ id: 'no future concerts' })}</h4>
    )
  return (
    <>
      {!hideTitle && <h4 css={titleCss}>{t({ id: 'next concerts' })}</h4>}
      <ConcertListDesktop {...props} />
      <ConcertListMobile {...props} />
    </>
  )
}

export const PastConcertList = ({ hideTitle, ...props }) => {
  const { formatMessage: t } = useIntl()

  if (props.concerts.length === 0)
    return <h4 css={titleCss}>{t({ id: 'no past concerts' })}</h4>
  return (
    <>
      {!hideTitle && <h4 css={titleCss}>{t({ id: 'past concerts' })}</h4>}
      <ConcertListDesktop {...props} hideBooking />
      <ConcertListMobile {...props} />
    </>
  )
}
